import React, { useState, useEffect, useRef } from "react";
import ShedulerDropdown from "../../Dropdown/Dropdown";
import { GoClock } from "react-icons/go";
import NotificationPopus from "../../Popup/Notification";
import { isMobile } from "react-device-detect";
import "../Upcoming/Upcoming.css";
import moment from "moment";
import momentduration from "moment-duration-format";
import { FaEllipsisV } from "react-icons/fa";
import RescheduleModal from "../../Popup/RescheduleModal";
import RejectModal from "../../Popup/RejectModal";

const UpcomingCard = ({ item }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [rejectModalShow, setRejectModalShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [notifdata, setnotifdata] = React.useState([]);

  const popupRef = useRef(null);
  const optionsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && !optionsRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showPopup]);

  return (
    <>
      <RescheduleModal
        show={modalShow}
        id={item?.Id}
        subject={item?.Subject_Val}
        date={item?.RequestStart_DtTm}
        startTime={item?.RequestStart_DtTm}
        endTime={item?.RequestedEnd_DtTm}
        description={item?.Description_Val}
        onHide={() => setModalShow(false)}
      />
      <RejectModal
        show={rejectModalShow}
        id={item?.Id}
        subject={item?.Subject_Val}
        date={item?.RequestStart_DtTm}
        startTime={item?.RequestStart_DtTm}
        endTime={item?.RequestedEnd_DtTm}
        description={item?.Description_Val}
        onHide={() => setRejectModalShow(false)}
      />
      <div
        className="col-md-12 col-lg-4"
        // onClick={(e) =>handlePopus(e)}
      >
        <div className="card card-conatiner card-border-meeting">
          <div className="card-head scheduler-card">
            {isMobile ? (
              <h3>{moment(item.RequestStart_DtTm).format("D MMM")}</h3>
            ) : (
              <h2>{moment(item.RequestStart_DtTm).format("D MMM")}</h2>
            )}

            {/* <ShedulerDropdown /> */}
          </div>
          <div id={item.eventId} className="card-body">
          <div ref={optionsRef} className="vertical-ellipsis" onClick={() => {setShowPopup(!showPopup)}}>
              <FaEllipsisV size={22} />
            </div>
            {showPopup && (
              <div ref={popupRef} className="popup" onClick={() => setShowPopup(false)}>
                {/* <div className="popup-item mb-2" onClick={() => setModalShow(true)}>Reschedule</div> */}
                <div className="popup-item" onClick={() => setRejectModalShow(true)}>Cancel</div>
              </div>
            )}
            <h4>{item.Subject_Val}</h4>
            <div className="card-body-head  mb-3">
              <GoClock />
              <span className="">
                Duration :{" "}
                {moment
                  .duration(
                    moment(item.RequestedEnd_DtTm).diff(
                      item.RequestStart_DtTm
                    ) / 1000,
                    "seconds"
                  )
                  .format("h [hrs], m [min]")}
              </span>
            </div>
            <div className="card-body-text mb-3">
              {item.Description_Val === ""
                ? "Description"
                : item.Description_Val}
            </div>
          </div>
          <div className="card-footer">
            <div className="bg-light-grey footer-duration primary-text mb-4">
              {moment(item.RequestStart_DtTm).format("h:mm a")} -{" "}
              {moment(item.RequestedEnd_DtTm).format("h:mm a")}
            </div>
          </div>
        </div>
      </div>
      {/* ):null) } */}
    </>
  );
};
export default UpcomingCard;
