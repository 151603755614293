import React, { useRef, useState, useEffect } from "react";
// import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import {Modal} from 'react-bootstrap'
import CreateEventForm from '../Form/CreateEventForm';
import "../Popup/Popup.css"
import CloseIcon from '../../../src/assets/icons/close.png'
import RejectEventForm from "../Form/RejectEventForm";

const RejectModal = (props) => {
  let modalSize="lg";

  return (
    <>
        <Modal
            size={modalSize}
            {...props}
        >
        <Modal.Header>
        <Modal.Title>Cancel Meeting</Modal.Title>
        <img
            src={CloseIcon}
            className="closeIcon"
            onClick={props.onHide}
        />
        </Modal.Header>
        <Modal.Body  >
        <RejectEventForm id ={props.id} subject ={props.subject} date={props.date} closePopup={props.onHide} startTime={props.startTime} endTime={props.endTime} description = {props.description}/>
        </Modal.Body>
    </Modal>
   </>
  );
};

export default RejectModal;
