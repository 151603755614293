import React, { useRef, useState, useEffect } from "react";
// import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import {Modal} from 'react-bootstrap'
import CreateEventForm from '../Form/CreateEventForm';
import "../Popup/Popup.css"
import CloseIcon from '../../../src/assets/icons/close.png'


export const Popup =  (props) => {

  const popupStyle = useRef('');
  console.log(props);
 
  const [show, setShow] = useState(false);
  let modalSize="lg";

 
 
  if(props.inValidDate){
    modalSize="sm";
    
    
    //popupStyle.current.style.margin = "0";
  }
  
  return (
    <>
    {
      props.inValidDate?
      <Modal
      size={modalSize}
      {...props}
    >
      <Modal.Header>
      
        <img
          src={CloseIcon}
          className="closeIcon"
          onClick={props.onHide}
        />
      </Modal.Header>
      <Modal.Body style={{margin:0}}>
        "Please select a Valid Date !"
      </Modal.Body>
    </Modal>
    :
     <Modal
     size={modalSize}
     {...props}
   >
     <Modal.Header>
     <Modal.Title>Create Event</Modal.Title>
       <img
         src={CloseIcon}
         className="closeIcon"
         onClick={props.onHide}
       />
     </Modal.Header>
     <Modal.Body  >
       <CreateEventForm date={props.date} closePopup={props.onHide} startTime={props.startTime} endTime={props.endTime}/>
     </Modal.Body>
   </Modal>
    }    </>
  );
};

export default Popup;
