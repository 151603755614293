import RejectedCard from "../Rejected/RejectedCard";
import UpcomingCard from "../Upcoming/UpcomingCard";
import PendingCard from "../Pending/PendingCard";
import "../Upcoming/Upcoming.css";
import React, { useEffect, useState } from "react";
import { apiHandler, getQueryParam } from "../../APIhandler/apiHandler";
import { apiList } from "../../../apiList";
import moment from "moment";
const Upcoming = (props) => {
  const [upcoming, setUpcoming] = useState([]);
  const [pending, setPending] = useState([]);
  const [events, setEvents] = useState([]);
  const [height, setHeight] = useState(500);
  const [stopScroll, setStopScroll] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getEvents(false, 1);
  }, []);
  const getEvents = (contactOrNot, page) => {
    let Id = getQueryParam("Id");
    let repId = Id.split("-")[0];
    let PresId = Id.split("-")[2];
    let type = Id.split("-")[4];
    apiHandler("get", apiList.getEvents + "/", {
      Employee_Id: repId,
      Participant_Id: PresId,
      PageSize: 20,
      Page: page,
      ParticipantType: type,
      Status_Val: "accept",
      StartDate: moment().subtract(1, 'hour').toISOString(),
      SortDirection: "ASC",
      SortColumn: "TIME"
    }).then((response) => {
      let pending = [];
      let upcoming = [];
      // debugger;
      let data = response.data.data;
      if (data.length == 0) {
        setStopScroll(true);
      }
      if (contactOrNot) {
        data = events.concat(data);
      }
     
      setPending(pending);
      setUpcoming(data);
      setEvents(data.concat(pending));
    });
  };
  const getHeight = () => {
    let viewportHeight = window.innerHeight;

    if (document.getElementById("scrollbox")) {
      let h =
        viewportHeight - document.getElementById("scrollbox").offsetTop - 50;
      return h;
    } else return "100%";
  };
  const scroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
   
    if (Math.ceil(scrollTop + clientHeight) === scrollHeight && !stopScroll) {
      setPage(page + 1);
      getEvents(true, page + 1);
    }
  };

  return (
    <div className="container content" id="upcoming-meeting">
      <h4 className="scheduled-title hidden">Scheduled Event</h4>
      <div
        id="scrollbox"
        onScroll={(e) => {
         
          scroll(e);
        }}
        style={{ height: getHeight() }}
      >
        <div className="row mt-4">
          {events.map((item) => {
            if (item.Status_Val == "accept")
              return <UpcomingCard key={item.Id} item={item} />;
            else return <PendingCard key={item.Id} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Upcoming;
