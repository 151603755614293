import RejectedCard from '../Rejected/RejectedCard';
import  UpcomingCard  from "../Upcoming/UpcomingCard";
import PendingCard from "../Pending/PendingCard";
import "../../pages/Upcoming/Upcoming.css"
import { apiHandler, getQueryParam } from '../../APIhandler/apiHandler';
import {apiList} from '../../../apiList';
import React,{useState,useEffect} from 'react';

const Notifications = (props) => {
  const [events,setEvents]=useState([]);
  const [page,setPage]=useState(1);
  const [stopScroll,setStopScroll]=useState(false);
  useEffect(() => {
    getEvents(false,1)
  }, [])
  const getEvents=(contactOrNot,page)=>{


    let Id= getQueryParam('Id')
    let repId=Id.split('-')[0];
    let PresId=Id.split('-')[0];
   
    apiHandler('get',(apiList.getEvents)+"/"+repId,
    {
     PageSize:30,
     Page:0,
     Status_Val:"accept,request,reject,reschedule"
   })
   .then(response=>{
    if(response.data.data.lenght==0)
    setStopScroll(true);
    let data=response.data.data.filter(x=>x.RequestStart_DtTm>=(new Date().toISOString()));

    if(contactOrNot){
      setEvents(events.concat(data))
    }else
    setEvents(data)
  });
  }

  const getHeight=()=>{
    let viewportHeight = window.innerHeight;
    if(document.getElementById('scrollbox')){
      let h=(viewportHeight - document.getElementById('scrollbox').offsetTop - 50);
      console.log(h)
      return h
    }
    else return "100%"
  }
  const scroll=(e)=>{

    
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      console.log(scrollTop, clientHeight,scrollHeight)
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight && !stopScroll) {
        setPage(page+1);
        getEvents(true,page+1);

    }
    
  }
  return (
    <div className="container content" >
      <h4 className='notif-title' >Notifications</h4>
    <div className="notification">
    <div id="scrollbox" onScroll={(e)=>{scroll(e)}} style={{ height:getHeight()}}>
    <div className="row mt-4">
      {
        events.map(item =>{
          if(item.Status_Val=="request")
          return <PendingCard item={item}/> 
          else if(item.Status_Val=="accept")
          return <UpcomingCard item={item}/>
          else 
          return <RejectedCard item={item}/>
        })
         
         
          
      }
    </div>
    </div>
    </div>
  </div>
  )
}
export default Notifications;
