
import './App.css';
import React, {useState,useEffect} from 'react'
import { BrowserRouter as Router,Routes,Route, Link } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import {HiMenuAlt1} from 'react-icons/hi'
import Home from './components/pages/Home/Home';
import Availability from './components/pages/Availability/Availability';
import Logo from './assets/img/Zing-logo.png';
import Desktopheader from './components/Navbar/DesktopHeader';
import ScheduledEvent from './components/pages/ScheduledEvent/ScheduledEvent';
import  Notifications  from './components/pages/Notifications/Notifications';
import {apiHandler,getQueryParam} from '../src/components/APIhandler/apiHandler';
import { apiList }  from '../src/apiList';
import { param } from 'jquery';
// import {useLocation} from 'react-router-dom';


function App() {

  document.documentElement.style
  .setProperty('--primary-color', process.env.REACT_APP_PRIMARY_COLOR);
  document.documentElement.style
  .setProperty('--mustard-color', process.env.REACT_APP_PENDING_TAB_COLOR);
  document.documentElement.style
  .setProperty('--red-color', process.env.REACT_APP_REJECTED_TAB_COLOR);

  const logoSrc = process.env.REACT_APP_CLIENT_NAME == 'usl' ? 'usl/img/logo':'img/Zing-logo';

  const img ={
    code:logoSrc
  }

  const [showNav,setShowNav] = useState(false);
  const [result, setResult] = React.useState([]);
  const [events,setEvents]=useState([]);
  
  useEffect(() => {
   
   // getFreeTime();
}, [])


function getFreeTime()
{

 let Id= getQueryParam('Id')
 let repId=Id.split('-')[0];
 let PresId=Id.split('-')[0];

 apiHandler('get',(apiList.getFreeTime)+"/"+repId,
 {
  StartDate:new Date().toISOString(),
  EndDate:new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString()
})
.then(response=>{setResult(response.data.data)});

apiHandler('get',(apiList.getEvents)+"/"+repId,
 {
  PageSize:5,
  Page:0,
  Status_Val:"request,reject,accept,reschedule"
})
.then(response=>{setEvents(response.data.data)});

}
console.log(result);
  return (
    <>
      <Router>
        {/* Header for mobile screen */}
        <header>
            <div className='header-sm hidden'>
            <HiMenuAlt1 className={showNav? 'header svg inverted': 'header svg'} onClick={() => setShowNav(!showNav)}/>
            <Link to="/" className="header-logo"><img src={require(`../src/assets/${img.code}.png`)} alt="logo" /></Link>
            </div>
            {/* Header compoenent for desktop screen */}
            <Desktopheader />
        </header>
          
        <Navbar show={showNav} setShowNav={()=>setShowNav(!showNav)}/>

        <div className='main'>
          <Routes>
              <Route path='/' exact={true} element={<Home result={result} events={events}/>}/>
              <Route path='/availability' exact={true}  element={<Availability />}/>
              <Route path='/scheduledevent' exact={true}  element={<ScheduledEvent result={events}/>}/>           
              <Route path='/notifications' exact={true}  element={<Notifications result={events}/>}/>           
          </Routes>
              
        </div>
      </Router>
    </>
  );
}

export default App;
