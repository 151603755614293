import React, { useState, useEffect } from 'react'
import '../PreferedTime/PreferedTime.css'
import moment from 'moment';
import { isMobile } from "react-device-detect";
const PreferedTime = (props) => {
  const [data, setdata] = useState([]);
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let temp=[];
    let propsData=props.preftime;
    propsData.data.map((e)=>{
      let index=temp.findIndex((tindex)=>moment(e.start).format("YYYY-MM-DD")==moment(tindex.date).format("YYYY-MM-DD"));
      if(moment(e.end)<moment())
      return e;
      if(index>=0){
        temp[index].data.push(e);
      }
      else{
        temp.push({
          date:e.start,
          data:[e]
        })
      }
      temp[index>=0?index:0].data.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.start) - new Date(b.start);
      });
      return e;
    })
    temp.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    console.log(temp)
    setdata(temp)
    
  },[props]);
 
  
  if(isMobile==true){
    return ( <div className="col-md-12 col-lg-4 p-0 preferedtime-card">
    {/* <div className="card text-center"> */}
      <div className="col-left text-center">
        <div className="card-head p-0 hidden-sm">
          <p className=" pt-3"><b>Preferred Time</b></p>
        </div>
        <hr className='hidden-sm' />
        <div className="card-body card-body__wrapper">
          {
            data.length>0?
            <div className='free-time'>
              {data.map(dates=>(
                <div>
                <p><b>{moment(dates.date).format('dddd, MMMM Do')}</b></p>
                <div className="row">
                  {
                    dates.data.map(times=>
                                 <div 
                                 onClick={()=>{
                                  props.onClick && props.onClick({date:dates.date,startTime:times.start,endTime:times.end})
                                  }}
                                 className="col-6 col-md-6 col-lg-6"><p className='slot-time'>{moment(times.start).format('h:mm a')} - {moment(times.end).format('h:mm a')}</p></div>
                  )
                  }
                </div>
                </div>
              ))}
            </div>
            :
            <>
            No preferred time on {moment(props.preftime.selectedDate).format('dddd, MMMM Do')}.
            </>
          }
                              
              
        </div>
      </div>
    {/* </div> */}
  </div>)
  }
  else{
    return(
    <div className="col-md-12 col-lg-4 p-0 preferedtime-card">
   
      <div className="col-left text-center">
        <div className="card-head p-0 hidden-sm">
          <p className=" pt-3"><b>Preferred Time</b></p>
        </div>
        <hr className='hidden-sm' />
        <div className="card-body card-body__wrapper">
          {
            data.length>0?
            <div className='free-time'>
              {data.map(dates=>(
                <div>
                <p><b>{moment(dates.date).format('dddd, MMMM Do')}</b></p>
                <div className="row">
                  {
                    dates.data.map(times=>
                                 <div 
                                 onClick={()=>{
                                  props.onClick && props.onClick({date:dates.date,startTime:times.start,endTime:times.end})
                                  }}
                                 className="col-6 col-md-6 col-lg-6"><p className='slot-time'>{moment(times.start).format('h:mm a')} - {moment(times.end).format('h:mm a')}</p></div>
                                  )
                  }
                </div>
                </div>
              ))}
            </div>
            :
            <>
            No preferred time in {moment(props.preftime.selectedDate).format('MMMM')}.
            </>
          }
                              
              
        </div>
      </div>  
  </div>
    )
 }
}
export default PreferedTime;