import React,{useEffect,useState} from 'react'
import RejectedCard from '../Rejected/RejectedCard';
import '../Rejected/Rejected.css'
import { apiHandler,getQueryParam } from '../../APIhandler/apiHandler';
import { apiList } from '../../../apiList';
 const Rejected = (props) => {
  const [events,setEvents]=useState([]);
  const [page,setPage]=useState(1);
  const [stopScroll,setStopScroll]=useState(false);
  useEffect(() => {
    getEvents(false,1)
  }, [])
  const getEvents=(contactOrNot,page)=>{

    let Id= getQueryParam('Id')
    let repId=Id.split('-')[0];
    let PresId=Id.split('-')[2];
    let type=Id.split('-')[4];
    apiHandler('get',(apiList.getEvents)+"/",
    {
     Employee_Id: repId,
     Participant_Id:PresId,
     PageSize:21,
     Page:page,
     ParticipantType:type,
     Status_Val:"reject,reschedule"
   })
   .then(response=>{

    if(response.data.data.lenght==0)
    setStopScroll(true);
    if(contactOrNot){
      
      setEvents(events.concat(response.data.data))
    }else
    setEvents(response.data.data)
  });
  }
  const getHeight=()=>{
    let viewportHeight = window.innerHeight;


    if(document.getElementById('scrollbox')){
      let h=(viewportHeight - document.getElementById('scrollbox').offsetTop - 50);
      console.log(h)
      return h
    }
    else return "100%"
  }
  const scroll=(e)=>{

    
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      console.log(scrollTop, clientHeight,scrollHeight)
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight && !stopScroll) {
        setPage(page+1);
        getEvents(true,page+1);

    }
    
  }

  return (
    <div className="container content" id="rejected-meeting">
      <div id="scrollbox" onScroll={(e)=>{scroll(e)}} style={{ height:getHeight()}}>
    <div className="row mt-4">
    
    {
              events.map(item =>
              <RejectedCard key={item.Id} item={item} />
              )
            }
        </div>
    </div>
  </div>
  )
}
export default Rejected;