import React, { useState,useEffect} from "react";
// import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import Availability from "../Availability/Availability";
import {useLocation} from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import Popup from "../../Popup/Popup";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Upcoming from '../Upcoming/Upcoming';
import Pending from '../Pending/Pending';
import Rejected from '../Rejected/Rejected';
import Calendar from "../Calendar/Calendar";
import "../../pages/Home/Home.css";
import api from '../../APIhandler/apiHandler';
// import apiList from './apiList';

export default function Home(props) {
  const [value, setValue] = useState("1");
  let data = useLocation();
   console.log(data);
  const navigate = useNavigate();
 
  const [modalShow, setModalShow] = React.useState(false);
  console.log(isMobile);

  
  
  const handleChange = (event,newValue) => {
    
    setValue(newValue);
  };


  
  useEffect(() => {
    console.log(isMobile, !isBrowser)
    if(localStorage.getItem("tab") && localStorage.getItem("tab")=="availability"){
      setValue("2");
    }
    else{
      setValue("1");
    }
  }, [])
  
  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        {isMobile ?(
          
          <TabContext value={value} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" className="tab">
            <Tab style={{whiteSpace:"nowrap"}} label="Book Meeting" value="1"  />

              <Tab label="Availability" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"><Calendar/></TabPanel>
          <TabPanel value="2" className="availability"><Availability result={props.result}/></TabPanel>
        </TabContext>
        ) : (
          <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example"  className="tab" >
          <Tab label="Calendar" value="1"  />
            <Tab label="Upcoming Meeting" value="2" />
            <Tab label="Approval Pending" value="3" />
            <Tab label="Rejected Meeting" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><Calendar /></TabPanel>
        <TabPanel value="2"><Upcoming /></TabPanel>
        <TabPanel value="3"><Pending /></TabPanel>
        <TabPanel value="4"><Rejected /></TabPanel>
      </TabContext>
        )}

        <Popup show={modalShow} onHide={() => setModalShow(false)} />
        {/* </div> */}
        
      </Box>
    </>
  );
}
