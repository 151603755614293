import React from "react";
import Popup from "../../components/Popup/Popup";
import Logo from '../../assets/img/Zing-logo.png';
import { FiPlus } from "react-icons/fi";
import "../Navbar/Desktop.css"
import { Link } from 'react-router-dom';


export const Desktopheader =  (props) => {

  const logoSrc = process.env.REACT_APP_CLIENT_NAME == 'usl' ? 'usl/img/logo':'img/Zing-logo';
   
    
    const img ={
      code:logoSrc
    }

  const [modalShow, setModalShow] = React.useState(false);
    function handleCreateEvent()
    { 
      setModalShow(true);
    }
    return(
    <div className="header-lg hidden-sm">
    <nav className="navbar">
      <Link to="/" className="header-lg-logo">
        <img src={require(`../../../src/assets/${img.code}.png`)} alt="logo" />
      </Link>
      <div className="header-lg-content">
        <Link to={{
    pathname: "/",
    search: window.location.search
  }} className="header-lg-btn btn btn-outline-light" data-toggle="modal" data-target="#staticBackdrop" onClick={handleCreateEvent}>
            <FiPlus />
            &nbsp;
            Create meeting
        </Link>
      </div>
    </nav>
    <Popup show={modalShow} onHide={() => setModalShow(false)} />
  </div>
  
    );

}
export default Desktopheader;
