import React, { useEffect, useRef, useState } from 'react'
import ShedulerDropdown from "../../Dropdown/Dropdown";
import { GoClock } from "react-icons/go";
import '../Pending/Pending.css'
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { FaEllipsisV } from 'react-icons/fa';
import RescheduleModal from '../../Popup/RescheduleModal';
import RejectModal from '../../Popup/RejectModal';

const PendingCard = ({item}) => {
  // console.log(props);
  const [showPopup, setShowPopup] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [rejectModalShow, setRejectModalShow] = useState(false);

  const startTime = item?.ProposedStart_DtTm;

  const popupRef = useRef(null);
  const optionsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && !optionsRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showPopup]);

  return (
    <>
      <RescheduleModal
        show={modalShow}
        id={item?.Id}
        subject={item?.Subject_Val}
        date={item?.RequestStart_DtTm}
        startTime={item?.RequestStart_DtTm}
        endTime={item?.RequestedEnd_DtTm}
        description={item?.Description_Val}
        onHide={() => setModalShow(false)}
      />
      <RejectModal
        show={rejectModalShow}
        id={item?.Id}
        subject={item?.Subject_Val}
        date={item?.RequestStart_DtTm}
        startTime={item?.RequestStart_DtTm}
        endTime={item?.RequestedEnd_DtTm}
        description={item?.Description_Val}
        onHide={() => setRejectModalShow(false)}
      />
    {/* {
    props.pendingdata.map(item => item.Status_Val=='request' &&item.RequestStart_DtTm>= (new Date().toISOString())? ( */}
            
            <div className="col-md-12 col-lg-4">
              <div className="card card-conatiner card-border-upcoimg-meeting">
                <div ref={optionsRef} className="vertical-ellipsis" onClick={() => {setShowPopup(!showPopup)}}>
                  <FaEllipsisV size={22} />
               </div>
               {showPopup && (
                  <div ref={popupRef} className="popup" onClick={() => setShowPopup(false)}>
                    {/* <div className="popup-item mb-2" onClick={() => setModalShow(true)}>Reschedule</div> */}
                    <div className="popup-item"  onClick={() => setRejectModalShow(true)}>Cancel</div>
                  </div>
                )}
                <div className="card-head scheduler-card justify-content-between">
                {isMobile?<h3 className="text-yellow">{moment(item.RequestStart_DtTm).format('D MMM')}</h3>: <h2 className="text-yellow">{moment(item.RequestStart_DtTm).format('D MMM')}</h2>}
                  {/* <ShedulerDropdown /> */}
                </div>
                <div className="card-body">
                  <h4>{item.Subject_Val}</h4>
                  <div className="card-body-head  mb-3">
                    <GoClock />
                    <span className="">Duration : {moment.duration(((moment(item.RequestedEnd_DtTm).diff(item.RequestStart_DtTm))/1000), "seconds").format("h [hrs], m [min]")}</span>
                  </div>
                  <div className="card-body-text mb-3">
                     {item.Description_Val===""?"Description":item.Description_Val}
                  </div>
                </div>
                <div className="card-footer">
                    <div className="footer-duration bg-yellow mb-4">
                    {moment(item.RequestStart_DtTm).format('h:mm a')} - {moment(item.RequestedEnd_DtTm).format('h:mm a')}
                    </div>
                  </div>
              </div>
            </div>
     {/* ):null) } */}
     </>
  )
}
export default PendingCard;
