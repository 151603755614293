import React, { useState, useEffect } from "react";
import "../Form/form.css";
import {apiHandler}from '../APIhandler/apiHandler';
import { apiList } from "../../apiList";

export default function RejectEventForm(props) {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [buttonDisable,setButtonDisable]=useState(true);
  const [rejecting, setIsRejecting]=useState(false);
  const [tempTimeStart,setTempTimeStart]=useState();
  const [tempTimeEnd,setTempTimeEnd]=useState();
  const [notes, setNotes] = useState("");
  
  const handleSubmit = (event) => {
    setButtonDisable(true);
    event.preventDefault();
      
    let param=window.localStorage.getItem('Id').split('-');
    let participantType = {
      ASSPRS : "prescriber_associate",
      PRESCR :"prescriber" 
    };

    const s_date=((new Date(tempTimeStart).toISOString()));
    const e_date=((new Date(tempTimeEnd).toISOString()));
    
    const data=
       {    
        "Id": props?.id,
        "Employee_id":parseInt(param[0]),
        "RequestStart_DtTm": s_date,
        "RequestedEnd_DtTm": e_date,
        "Subject_Val": name,
        "Description_Val": desc,
        "Status_Val": "reject",
        "ProposedStart_DtTm": s_date,
        "ProposedEnd_DtTm": e_date,
        "RejectReason_Val": notes,
        "AlteredBy_Id": parseInt(param[2]),
        "AlteredBy_Typ": param[4],
        "Participants": [
            {
                "IsHost_Flg": "Y",
                "Participant_Id":parseInt(param[2]),
                "ParticipantType":participantType[param[4]],
                "Status_Val": "request",
                "ParticipantPhone_Val": "",
                "ParticipantEmail_Val": "",
                "ParticipantCountryCode_Val": ""
            }
        ],
    }

    const headers = {'Content-Type': 'application/json'};
      
    setIsRejecting(true);
    apiHandler('post',(apiList.meetingRequest), data,headers)
    .then((res) => {
      props.closePopup();
      setIsRejecting(false);
      window.location.reload(false);
      setButtonDisable(false);
    }).catch((e) => {
      setButtonDisable(false);
      setIsRejecting(false);
    });
  };

  useEffect(() => {
      if(props.subject) {
        setName(props.subject);
      }
      if(props.description) {
        setDesc(props.description);
      }

      if(props.date) {
          if(props.startTime){
            setTempTimeStart(new Date(props.startTime))
          }
          else {
              setTempTimeStart(new Date(new Date(props.date).toISOString().slice(0,11)+"00:00"))
          }

          if(props.endTime){
            setTempTimeEnd(new Date(props.endTime));
          }
          else {
            setTempTimeEnd(new Date(new Date(props.date).toISOString().slice(0,11)+"00:00"))
          }
      }
      else {  
        setTempTimeStart(new Date(new Date().toISOString().slice(0,11)+"00:00"))
        setTempTimeEnd(new Date(new Date().toISOString().slice(0,11)+"00:00"))
      }
  }, []);

  useEffect(() => {
    if(notes) {
      setButtonDisable(false);
    }
    else {
      setButtonDisable(true);
    }
  }, [notes]);

  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="mb-4">
          <textarea 
            id="subject" 
            rows={6}
            cols={6} 
            maxLength={400} 
            placeholder="Type your message here" 
            value={notes} 
            disabled={false} 
            required
            onChange={(e) => {
                setNotes(e.target.value);
            }} 
          />
        </div>

        <div className="form-footer-container">
          <button type="submit" className={`${buttonDisable ? "footer-btn-disabled" : "footer-btn"} mb-5`} disabled={buttonDisable} >
          {rejecting ? <span>Rejecting</span> : <span>Reject</span>} 
          </button>
        </div>
      </div>
    </form>
    </>
  );
}
