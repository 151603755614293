import React from 'react';
import {Link} from 'react-router-dom'
const Dropdown = () => {
  return(
        <ul className='sidenav-dropdown'>
             {/* <li className='sidenav-dropdown-item'>
                <Link to='/video'>Video</Link>
            </li> 
            <li className='sidenav-dropdown-item'>
                <Link to='/audio'>Audio</Link>
            </li> 
            <li className='sidenav-dropdown-item'>
                <Link to='/inperson'>Inperson</Link>
            </li>  */}
        </ul>
   
  )
};

export default Dropdown;
