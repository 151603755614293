import React,{useState,useEffect}from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router";
import './Availability.css';
import PreferedTime from '../PreferedTime/PreferedTime';
import { apiHandler,getQueryParam } from '../../APIhandler/apiHandler';
import { apiList } from '../../../apiList';
import Popup from "../../Popup/Popup";
import moment from 'moment';
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import { FaChevronRight,FaChevronLeft } from "react-icons/fa";
const Availability = (props) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [modalShow, setModalShow] = React.useState(false);
  const [enablePopup, setEnablePopup] = useState(false);
    const [showInvalidDateMsg, setShowInvalidDateMsg] = useState(false);
    const [event, setEvent] = useState([]);
    const [availabilitydata, setAvailability] = useState({selectedDate:new Date(),data:[]});
    const [calendarDateClick,setCalendarDateClick]=useState(new Date());
    const [startTime,setStartTime]=useState("")
    const [endTime,setEndTime]=useState("")
    const [freeTime,setFreeTime]=useState([])

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };


  const onDateClickHandle = (day, dayStr) => {
    // debugger
     
     getFreeTime(moment(day).format('YYYY-MM-DD'),moment(day).endOf("month").format("YYYY-MM-DD"));
    // // alert(dayStr);
    // debugger
    // let temp =props.result;
    // let tepData=[];
    // temp.map(x=>{
    //   x.start=x.AvailabilityStart_DtTm;
    //   x.end=x.AvailabilityEnd_DtTm;
    //   x.description=x.Subject_Val;
    //   var currDate = moment(x.start).startOf('day');
    //   var lastDate = moment(x.end).startOf('day');
    //   if(moment(x.start).format("YYYY-MM-DD")!=moment(x.end).format("YYYY-MM-DD")){
    //     tepData.push({
    //       description: x.description,
          
    //       eventId: 0,
    //       repAccepted: x.repAccepted,
    //       repRejcted: x.repRejcted,
      
    //       subject:x.subject,
    //       start:x.start,
    //       end:moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+"T23:59:00")).format("YYYY-MM-DDTHH:mm:ss")
    //     })
    //     // console.log("start->",moment().format("YYYY-MM-DD h:mm a"));
    //     // //console.log(moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+"T00:00:00")).format())
    //     // console.log("end->",moment().format("YYYY-MM-DD h:mm a"));
    //     while(currDate.add(1, 'days').diff(lastDate) < 0) {
    //       tepData.push({
    //         description: x.description,
          
    //       eventId: 0,
    //       repAccepted: x.repAccepted,
    //       repRejcted: x.repRejcted,
      
    //       subject:x.subject,
    //         start:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+"T00:00:00")).format("YYYY-MM-DDTHH:mm:ss"),
    //         end:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+"T23:59:00")).format("YYYY-MM-DDTHH:mm:ss")
    //       })
    //     }
    //     tepData.push({
    //       description: x.description,
          
    //       eventId: 0,
    //       repAccepted: x.repAccepted,
    //       repRejcted: x.repRejcted,
      
    //       subject:x.subject,
    //       start:moment.utc(moment(moment(x.end).format("YYYY-MM-DD")+"T00:00:00")).format("YYYY-MM-DDTHH:mm:ss"),
    //       end:x.end
    //     })

    //   }
    //   else
    //   tepData.push(x);
      
    //   return x;
    // })
    // const dateval=moment(dayStr).format("YYYY-MM-DD");
    // let tempAvailabilitydata=availabilitydata;
    //       tempAvailabilitydata.selectedDate=dateval;
    //       tempAvailabilitydata.data=[]
    // for(var i=0;i<tepData.length;i++)
    // {
    //   const splitval=moment(tepData[i].start).format("YYYY-MM-DD");
    //   if(splitval==dateval)
    //   {  
    //     tempAvailabilitydata.data.push(tepData[i])   
    //      //setAvailability(props.result[i]);
    //   }    
       
    //  }
    //  setAvailability(tempAvailabilitydata);
  //  showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    // console.log("selected day", selectedDate);
    return (
    
      <div className="row header flex-middle">
      <div className="col col-start">
        <div className="icon-left" onClick={() => changeWeekHandle("prev")}>
             <FaChevronLeft style={{width:"20%",height:"40%"}}/>
        </div>
        {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
          prev month
        </div> */}
      </div>
      <div className="col col-center">
        <span className="monthyear">{format(currentMonth, dateFormat)}</span>
      </div>
      <div className="col col-end">
              <div className="col col-end" onClick={() => changeWeekHandle("next")}>
              <div className="icon-right"><FaChevronRight style={{width:"20%",height:"40%"}}/></div>
              </div>
        {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
      </div>
    </div>
    
    );
    
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    // debugger
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col text-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        days.push(
          <div
            className={`col cell text-center ${ moment(day).isSame(new Date())? "today": moment(day).isSame(selectedDate)? "selected": ""
            }`}
            key={day}
            onClick={() => {

              const dayStr = format(cloneDay, "ccc dd MMM yy");
              getFreeTime(moment(cloneDay).format('YYYY-MM-DD'),moment(cloneDay).endOf("month").format("YYYY-MM-DD"));
             // onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            {/* <span className="bg">{formattedDate}</span> */}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const getFreeTime=(start,end)=>{
    // debugger
    setSelectedDate(start);
    let Id= getQueryParam('Id')
    let repId=Id.split('-')[0];
    apiHandler('get',(apiList.getFreeTime)+"/"+repId,
    {
     StartDate:new Date(start).toISOString(),
     EndDate:new Date(end).toISOString()
   })
   .then(response=>{
    // debugger
    validateData(response.data.data,start);
    // setFreeTime(response.data.data);
  });
  }
  const validateData=(data,date)=>{
    let temp =data;
    let tepData=[];
    temp.map(x=>{
      x.start=x.AvailabilityStart_DtTm;
      x.end=x.AvailabilityEnd_DtTm;
      x.description=x.Subject_Val;
      x.subject=x.Subject_Val;
      var currDate = moment(x.start).startOf('day');
      var lastDate = moment(x.end).startOf('day');
      if(moment(x.start).format("YYYY-MM-DD")!=moment(x.end).format("YYYY-MM-DD")){
        tepData.push({
          description: x.description,
          eventId: 0,
          repAccepted: x.repAccepted,
          repRejcted: x.repRejcted,
          subject:x.subject,
          start:x.start,
          end:moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+" 23:59:00Z")).format("YYYY-MM-DDTHH:mm:ss")
        })
        // console.log("start->",moment().format("YYYY-MM-DD h:mm a"));
        // //console.log(moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+"T00:00:00")).format())
        // console.log("end->",moment().format("YYYY-MM-DD h:mm a"));
        while(currDate.add(1, 'days').diff(lastDate) < 0) {
          tepData.push({
          description: x.description,
          eventId: 0,
          repAccepted: x.repAccepted,
          repRejcted: x.repRejcted,
          subject:x.subject,
          start:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+" 00:00:00Z")).format("YYYY-MM-DDTHH:mm:ss"),
          end:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+" 23:59:00Z")).format("YYYY-MM-DDTHH:mm:ss")
          })
        }
        tepData.push({
          description: x.description,
          
          eventId: 0,
          repAccepted: x.repAccepted,
          repRejcted: x.repRejcted,
      
          subject:x.subject,
          start:moment.utc(moment(moment(x.end).format("YYYY-MM-DD")+" 00:00:00Z")).format("YYYY-MM-DDTHH:mm:ss"),
          end:x.end
        })

      }
      else
      tepData.push(x);
      return x;
    })
    const dateval=moment(date).format("YYYY-MM-DD");
    let tempAvailabilitydata={
      selectedDate:dateval,
      data:[]
    };
          // tempAvailabilitydata.selectedDate=dateval;
          // tempAvailabilitydata.data=[]
    for(var i=0;i<tepData.length;i++)
    {
      const splitval=moment(tepData[i].start).format("YYYY-MM-DD");
      if(splitval==dateval)
      {  
        tempAvailabilitydata.data.push(tepData[i])   
         //setAvailability(props.result[i]);
      }    
       
     }
     console.log(tempAvailabilitydata)
     setAvailability(tempAvailabilitydata);
  }

  // useEffect(() => {
  //     console.log(availabilitydata)
  // }, [availabilitydata])
  
  useEffect(() => {
    // debugger
    getFreeTime(moment().format('YYYY-MM-DD'),moment().endOf("month").format("YYYY-MM-DD"));
    //onDateClickHandle(new Date(),new Date())
  }, [])
  
    return(
      <>
        <Box  sx={{  bgcolor: 'background.paper' }}>
        <div className='calendar calendar-availability' style={{position: "relative",
        margin:"0rem"
        }}>
             {/* <AvailabilityCalendar result={props.result} />    */}
             <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}    
    </div>    
             {/* <PreferedTime /> */}
        </div >
        
      </Box>
      <PreferedTime onClick={(e)=>{
        if(moment().diff(moment(e.date), 'days')>0)
        {
          setShowInvalidDateMsg(true)
          setModalShow(true)
        }
        else{
          setCalendarDateClick(moment().format("YYYY-MM-DD"))
          setStartTime(e.startTime)
          setEndTime(e.endTime)
          setModalShow(true)
        }
      }} key={availabilitydata.selectedDate} preftime={availabilitydata}/>
      <Popup show={modalShow} inValidDate={showInvalidDateMsg} date={calendarDateClick} startTime={startTime} endTime={endTime} onHide={() => {setStartTime('');setEndTime(''); setModalShow(false)}} />
     </>
      
     
  ) 
};

export default Availability;
