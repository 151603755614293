import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import {HiChevronDown} from 'react-icons/hi'
import Logo from '../../assets/img/Zing-logo.png';
import Dropdown from './Dropdown';

const Navbar = ({show,setShowNav}) => {

    const logoSrc = process.env.REACT_APP_CLIENT_NAME == 'usl' ? 'usl/img/logo':'img/Zing-logo';

    const img ={
      code:logoSrc
    }

    const [showDropDown,setShowDropDown] =useState(false);
    const [hidenav,setHideNav] = useState(false);

    const hideNavbar = () =>setHideNav(hidenav);
    const reloadOrNot=()=>{
        if(window.location.pathname=="/")
        window.location.reload(false);
    }
  return(
            //   sidenav for mobile screen
            <div className={show ? 'sidenav active' : 'sidenav'} >
                {/* sidenav top image */}
                    <div className='sidenav-top-img'></div>   
                    {/* sidenav menu item  */}
                    <ul>
                        <li className='sidenav-item'>
                            <Link to='/' className="active" onClick={(e)=>{reloadOrNot();setShowNav();window.localStorage.setItem("tab","");}}>Meeting </Link>
                        </li>
                        {/* sidenav submenu item */}
                        {showDropDown && 
                           <Dropdown/>
                        }                                           
                        
                        <li className='sidenav-item'>
                            <Link 
                            // to={{
                            //         pathname: "/",
                            //         state: { name: 'availability'}
                            //     }}
                            to={`/`}
                            state={{ name: 'availability'}}
                                 onClick={(e)=>{reloadOrNot();setShowNav();hideNavbar(e);window.localStorage.setItem("tab","availability");}}>Availability
                          </Link>
                        </li> 
                        <li  className='sidenav-item'>
                            <Link to='/scheduledevent'  onClick={setShowNav}>Scheduled Event</Link>
                        </li> 
                        <li className='sidenav-item'>
                            <Link to='/notifications'  onClick={setShowNav}>Notifications</Link>
                        </li> 
                        
                    </ul>
                    {/* sidenav bottom image */}
                    <div className='sidenav-bottom-img'>
                    <img src={require(`../../../src/assets/${img.code}.png`)} alt="logo" />
                    </div>
            </div>
  ) 
};

export default Navbar;
