import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Upcoming from '../Upcoming/Upcoming';
import Pending from '../Pending/Pending';
import Rejected from '../Rejected/Rejected';
import api from '../../APIhandler/apiHandler';


const ScheduledEvent = (props) => {
 const [value, setValue] = React.useState('1');
 const handleChange = (event, newValue) => {
 setValue(newValue);
 };

 useEffect(() => {

 }, [])
 
 return(
 

      <div className='ScheduledEvent'>
      <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Upcoming" value="1" />
                            <Tab label="Pending" value="2" />
                            <Tab label="Rejected" value="3" />
                      </TabList>
                 </Box>

                <TabPanel value="1"><Upcoming result={props.result}/></TabPanel>
                <TabPanel value="2"><Pending result={props.result}/></TabPanel>
                <TabPanel value="3"><Rejected result={props.result}/></TabPanel>
            </TabContext>

      </Box>
      </div>


 

);

}



export default ScheduledEvent;