import React, { useState, useEffect } from "react";
import "../Form/form.css";
import {useLocation} from 'react-router-dom';
import {apiHandler}from '../APIhandler/apiHandler';
import { apiList } from "../../apiList";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

export default function RescheduleEventForm(props) {
  const { addOrEdit, recordForEdit } = props;
  const [name, setName] = useState("");
  const [startdate, setStartDate] = useState("");
  const [formresult, setformResult] = useState("");
  const [enddate, setEndDate] = useState("");
  const [desc, setDesc] = useState("");
  const [notes, setNotes] = useState("");
  const search = useLocation().search; 
  const [IdVar,setIdVar]= useState("");
  const [error,setError]=useState(false)
  const [buttonDisable,setButtonDisable]=useState(false);
  const [lessThanCurrent,setLessThanCurrent]=useState(false);
  const [inValidDate,setInValidDate]=useState(false);
  const [creating,setCreating]=useState(false);
  const [endTimeRadio, setEndTimeRadio] = useState("15");
  const [tempTimeStart,setTempTimeStart]=useState()
  const [tempTimeEnd,setTempTimeEnd]=useState()
  const conf = require('../../env.json');
  
  const handleSubmit = (event) => {
    setButtonDisable(true);
    const Id =  IdVar
    event.preventDefault();
    let Edate=tempTimeEnd;
     if(endTimeRadio!="other"){
    Edate=moment(tempTimeStart).add(endTimeRadio, 'minutes').format("YYYY-MM-DDTHH:mm")
    }
    const s_date=((new Date(tempTimeStart).toISOString()));
    const e_date=((new Date(Edate).toISOString()));
    
    if(s_date<new Date().toISOString() || e_date<new Date().toISOString()){
      setLessThanCurrent(true);
      setButtonDisable(false);
    }
    else if(s_date>=e_date)
    {
      setError(true);
      setButtonDisable(false); 
    }
    else{
      console.log(name,s_date,e_date,desc);
      
    let param=window.localStorage.getItem('Id').split('-');
    let participantType = {
      ASSPRS : "prescriber_associate",
      PRESCR :"prescriber" 
    };

    let newScheduleMeetingURL = conf.MEETING_SCHEDULAR_UI_ENDPOINT +
    `/?Id=${parseInt(param[0])}-T-${parseInt(param[2])}-T-${param[4]}`;
    
    const data={    
        "Id": props?.id,
        "Employee_id":parseInt(param[0]),
        "RequestStart_DtTm": s_date,
        "RequestedEnd_DtTm": e_date,
        "Subject_Val": name,
        "Description_Val": desc,
        "Status_Val": "reschedule",
        "RejectReason_Val": notes,
        "ProposedStart_DtTm": s_date,
        "ProposedEnd_DtTm": e_date,
        "AlteredBy_Id": parseInt(param[2]),
        "AlteredBy_Typ": param[4],
        "Participants": [
            {
                "IsHost_Flg": "Y",
                "Participant_Id":parseInt(param[2]),
                "ParticipantType":participantType[param[4]],
                "Status_Val": "request",
                "ParticipantPhone_Val": "",
                "ParticipantEmail_Val": "",
                "ParticipantCountryCode_Val": "",
            }
        ],
        "scheduler_link": newScheduleMeetingURL
      }

      const headers = {'Content-Type': 'application/json'};
      
      setCreating(true);
      apiHandler('post',(apiList.meetingRequest), data,headers)
      .then(response=>{
        console.log("Result"+ response);
        props.closePopup();
        setCreating(false);
        window.location.reload(false);
        setButtonDisable(false);
      }).catch(e=>{
       setButtonDisable(false);
       setCreating(false);
     })
     
    }
  };

const validateDateTime=(value)=>{

  if(new Date(value)=="Invalid Date" || !value)
  {
    setInValidDate(true);
    setButtonDisable(true); 
  }
  else{
    setInValidDate(false);
    setButtonDisable(false); 
  }
} 
function onChangeValue(event) {
  setEndTimeRadio(event.target.value);
  setError(false);setLessThanCurrent(false);   
}
useEffect(() => {
    if(props.subject) {
        setName(props.subject);
    }
    if(props.description) {
        setDesc(props.description);
    }

    if(props.date){

        if(props.startTime){
            setTempTimeStart(new Date(props.startTime))
        }
        else{
            setTempTimeStart(new Date(new Date(props.date).toISOString().slice(0,11)+"00:00"))
        }
        if(props.endTime){
            setTempTimeEnd(new Date(props.endTime));

            const startTime = new Date(props.startTime);
            const endTime = new Date(props.endTime);
            if ((endTime - startTime)/(1000*60) == 15){
                setEndTimeRadio('15');
            }
            else if ((endTime - startTime)/(1000*60) == 30){
                setEndTimeRadio('30');
            }
            else if ((endTime - startTime)/(1000*60) == 60){
                setEndTimeRadio('60');
            }
            else {
                console.log("diff", ((endTime - startTime)/(1000*60)) == 30)
                setEndTimeRadio('other');
            }
        }
        else
        setTempTimeEnd(new Date(new Date(props.date).toISOString().slice(0,11)+"00:00"))
        }
        else{
            setTempTimeStart(new Date(new Date().toISOString().slice(0,11)+"00:00"))
        setTempTimeEnd(new Date(new Date().toISOString().slice(0,11)+"00:00"))
        }
}, [])

  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="input-text mb-4">
          <input 
            id="subject" 
            type="text" 
            maxLength={150} 
            placeholder="Subject" 
            value={name} 
            disabled={true} 
            required 
          />
          <label> Subject</label>
        </div>
        <div className="input-text mb-4 availability">
          <label> Availability</label>
          <div className="availability-input">
            <div>
              <label>Start:</label>
              <div className="flex">
                <DatePicker
                    calendarClassName="datePickerNew"
                    selected={tempTimeStart}
                    minDate={new Date()}
                    maxDate={new Date(new Date().setMonth(new Date().getMonth() + 12))}
                    dateFormat="MM/dd/yyyy"
                    timeIntervals={5}
                    onChange={(e) => {validateDateTime(e); setError(false);setLessThanCurrent(false); setTempTimeStart(e)}}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
 
                <TimePicker
                    value={moment(tempTimeStart, "hh:mm")}
                    showSecond={false}
                    use12Hours={true}
                    minuteStep={5}
                    allowEmpty={false}
                    onChange={(e)=>setTempTimeStart(new Date(moment(tempTimeStart).format("YYYY-MM-DD")+"T"+moment(e).format("HH:mm")))}
                />
            </div>
            
            </div>
                <div onChange={onChangeValue} className="radioEndTime">
                <div>  <input type="radio" value="15" name="endTimeRadio" checked={endTimeRadio === "15"} /> 15 mins</div>
                <div>  <input type="radio" value="30" name="endTimeRadio" checked={endTimeRadio === "30"}/> 30 mins</div>
                <div>  <input type="radio" value="60" name="endTimeRadio" checked={endTimeRadio === "60"} /> 1 hour</div>
                <div>  <input type="radio" value="other" name="endTimeRadio" checked={endTimeRadio === "other"} /> Other</div>
            </div>
            {
            endTimeRadio == "other"?
            <div>
            <label >End:</label>
            <div className="flex">
                <DatePicker
                    calendarClassName="datePickerNew "
                    selected={tempTimeEnd}
                    minDate={new Date()}
                    maxDate={new Date(new Date().setMonth(new Date().getMonth() + 12))}
                    dateFormat="MM/dd/yyyy"
                    timeIntervals={5}
                    onChange={(e) => {validateDateTime(e); setError(false);setLessThanCurrent(false); setTempTimeEnd(e)}}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <TimePicker               
                    value={moment(tempTimeEnd, "hh:mm")}
                    showSecond={false}
                    use12Hours={true}
                    minuteStep={5}                   
                    allowEmpty={false}
                    onChange={(e)=>setTempTimeEnd(new Date(moment(tempTimeEnd).format("YYYY-MM-DD")+"T"+moment(e).format("HH:mm")))}
                />
            </div>
           
             {/* <input required type="datetime-local" id="meeting-time-end" name="meeting-time" max={new Date(new Date().setMonth(new Date().getMonth() + 12)).toISOString().slice(0,16)} min={startdate?new Date(startdate.slice(0,10)).toISOString().slice(0,11)+"00:00":new Date().toISOString().slice(0,11)+"00:00"} value={enddate} onChange={(e) => {validateDateTime(e.target.value); setError(false);setLessThanCurrent(false); setEndDate(e.target.value)}}/> */}
             </div>:null
        }
                
                {
            inValidDate?
            <div id="error" style={{color:"red"}}>Invalid Date-time.</div>
            :
            null
            }
                
                {
            error?
            <div id="error" style={{color:"red"}}>End date-time cannot be less or equal than start date-time.</div>
            :
            null
            }
            {
            lessThanCurrent?
            <div id="error" style={{color:"red"}}>Date-time cannot be less than current date-time.</div>
            :
            null
            }
            </div>
            
         
        </div>
        <div className="input-text mb-4">
          <textarea 
            disabled={true}
            name="description" 
            maxLength={150} 
            id="" 
            cols="30" 
            rows="1" 
            value={desc}
          ></textarea>
          <label> Description</label>
        </div>
        <div className="input-text mb-4">
          <textarea 
            name="notes" 
            maxLength={200} 
            id="" 
            cols="30" 
            rows="3" 
            value={notes}
            onChange={(e) => 
              {
                const result = e.target.value.replace(/[^A-Za-z0-9 _.]/gi, '');
                  setNotes(result);
              }
            }
          ></textarea>
          <label> Notes</label>
        </div>
        <div className="form-footer-container">
          <button type="submit" className="footer-btn mb-5" disabled={buttonDisable} >
          {creating?<span>Updating</span>:<span>Reschedule</span>} 
          </button>
        </div>
      </div>
    </form>
    </>
  );
}
