import React from "react";
import axios from 'axios'
//import config from '../../env.json'
const conf = require('../../env.json');
axios.defaults.baseURL = conf.REACT_APP_CALENDAR_API_BASE;

export async function apiHandler (method, url, params,header) {
    const body = method === 'get' ? 'params' : 'data'
    
    const config = {
        method,
        url,
        baseURL: conf.REACT_APP_CALENDAR_API_BASE,
        [body]: params || {},
        headers: header || {}, 
    }
    
       return axios.request(config)
     
}

export function getQueryParam(){

    if(window.location.search.length>0){
    if(window.location.search.split("Id=")[1]){
    window.localStorage.setItem("Id",window.location.search.split("Id=")[1])
    return window.location.search.split("Id=")[1];
      }
      else
      return ""
    }
    else{
      if( window.localStorage.getItem("Id"))
      return window.localStorage.getItem("Id")
      else
      return ""
    }
  
  }
  
